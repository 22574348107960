<template>
  <div class="yt-main" style="padding-top: 0">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>专业管理</el-breadcrumb-item>
        <el-breadcrumb-item>技能管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div
      class="yt-container yt-container-flex"
      :class="{
        'is-pack-up': isPackUp
      }"
    >
      <div class="yt-content-tree has-footer">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>技能列表</p>
          </div>
          <el-input
            v-model="keyword"
            class="yt-search search"
            style="margin-bottom: 8px"
            placeholder="通过技能名称搜索"
            @keydown.enter.native="toFilterNodes('functionAbility-tree')"
          >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNodes('functionAbility-tree')"></i>
          </el-input>
          <div class="tree has-tool" style="height: calc(100% - 146px); margin-top: 0">
            <el-tree
              ref="functionAbility-tree"
              :data="skillsData"
              node-key="id"
              :highlight-current="true"
              @node-click="handleNodeClick"
              :filter-node-method="filterNode"
            >
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ data.name }}</div>
                </el-tooltip>
                <div class="tools">
                  <span class="tool-button" @click.stop="toUpdateFunctionAbility(data)">修改</span>
                  <span class="tool-button" @click.stop="toDeleteFunctionAbility(data)">删除</span>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="footer">
            <el-button style="width: 138px" size="small" @click="toCreateFunctionAbility(true)">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <p>新建技能</p>
              </div>
            </el-button>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <div class="top-content">
          <div class="top-lable">
            <p>技能名称：</p>
            <el-tooltip popper-class="tooltip" content="点击可查看技能详情" placement="top">
              <YTIcon :href="'#icon-tishi1'"></YTIcon>
            </el-tooltip>
            <div class="ability-name" @click="openAbilityInfo" style="cursor: pointer">
              <span>{{ abilityName }}</span>
            </div>
          </div>
          <el-input
            v-model="knowledgeKeyword"
            style="width: 196px ;"
            class="yt-search search"
            placeholder="通过知识点搜索"
            @keydown.enter.native="searchKnowledge"
          >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchKnowledge"></i>
          </el-input>
        </div>
        <div class="line"></div>
        <div class="middle-content">
          <div style="width: 70px"><p>技能描述:</p></div>
          <el-tooltip class="item" popper-class="tooltip" content="可以添加或编辑该技能的描述" placement="top">
            <YTIcon :href="'#icon-tishi1'"></YTIcon>
          </el-tooltip>
          <el-input
            type="textarea"
            resize="none"
            :rows="3"
            maxlength="200"
            placeholder="请输入内容"
            v-model="describe"
            style="margin-top: 17px;margin-left: 5px "
            @blur="editDescribeInfo"
          >
          </el-input>
        </div>
        <div class="line"></div>
        <div class="bottom-content">
          <p>子知识点：</p>
          <el-tooltip class="item" popper-class="tooltip" content="点击可查看知识点详情" placement="top">
            <YTIcon :href="'#icon-tishi1'" class="icon-svg"></YTIcon>
          </el-tooltip>
          <div style="margin-top: 15px;margin-left: 95px ;position: absolute;padding-right: 10px;">
            <div style="display: flex;flex-flow: wrap;overflow-y: auto">
              <div v-for="tag in knowledgeList" :key="tag.knowledgeId" :class="tag.isParent ? 'have tag' : 'not-have tag-item'">
                <YTIcon :href="'#icon-beijing'" :class="tag.isParent ? 'second-icon' : 'first-icon'"></YTIcon>
                <el-tag closable @close="delOrgAbilityknowledge(tag)">
                  <span style="cursor: pointer" @click="getTree(tag.knowledgeId)">{{
                    tag.knowledgeName.length > 12 ? tag.knowledgeName.substring(0, 12) + '...' : tag.knowledgeName
                  }}</span>
                </el-tag>
              </div>
              <template v-if="iconFlag">
                <el-dropdown placement="bottom-start">
                  <div><YTIcon :href="'#icon-xinzeng'" class="add-icon"></YTIcon></div>
                  <el-dropdown-menu slot="dropdown">
                    <el-input
                      v-model="knowledgePointUpdateKeyword"
                      placeholder="通过知识点名称筛选"
                      class="yt-search search"
                      @keydown.enter.native="toFilterNodeUpdate('knowledge-tree-update', knowledgePointUpdateKeyword)"
                    >
                      <i
                        slot="suffix"
                        class="el-input__icon el-icon-search"
                        @click="toFilterNodeUpdate('knowledge-tree-update', knowledgePointUpdateKeyword)"
                      ></i>
                    </el-input>
                    <div class="list-container" style="height: calc(100vh - 500px);">
                      <el-tree
                        :data="knowledgePointsUpdate"
                        :props="knowledgePointProps"
                        node-key="value"
                        ref="knowledge-tree-update"
                        :filter-node-method="filterNode"
                        show-checkbox
                        :check-strictly="flag"
                        @check="setTreeState"
                      >
                      </el-tree>
                    </div>
                    <div class="footer">
                      <el-button type="primary" @click="toSearchByKnowledge">确定</el-button>
                      <el-button size="small" @click="resetCheckedNodesDown('knowledge-tree-down')">
                        <div>
                          <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                          <p>重置</p>
                        </div>
                      </el-button>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </div>
          </div>
        </div>
        <div class="knowledge-content" v-show="abilityState">
          <div style="display: flex">
            <p>技能-知识点关系详情</p>
            <i
              class="el-icon-close"
              style="margin-left: auto;
              width: 16px;
              height: 16px;
              cursor: pointer;
            "
              @click="closeAbilityInfo"
            ></i>
          </div>
          <el-button
            plain
            @click="resetTree"
            style="width: 60px;
            height: 26px;
          margin-left: 92%;"
            >重置</el-button
          >
          <v-chart ref="chart" @click="resize" autoresize class="chart" style="height:90%;width: 100% " :options="ds" />
        </div>
      </div>
    </div>
    <FunctionAbilityCUDialog ref="functionAbilityCUDialog" @refresh="getOrgAbility"></FunctionAbilityCUDialog>
    <ConfirmDialog ref="deleteDialog" :title="'删除技能'" @on-ok="delAbility">
      <div style="width: 270px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px" />
        <span>是否确认删除该技能,删除后将不可恢复</span>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import abilityApi from '@api/ability'
import FunctionAbilityCUDialog from '../../common/dialog/FunctionAbilityCUDialog'
import ConfirmDialog from '../../common/dialog/ConfirmDialog'
import YTIcon from '../../common/YTIcon'
import questionApi from '@api/question'
import knowledgeApi from '@api/knowledge'
import icon from '@assets/caozuo.png'
export default {
  name: 'Ability',
  components: { FunctionAbilityCUDialog, ConfirmDialog, YTIcon },
  data() {
    return {
      keyword: '',
      knowledgeKeyword: '',
      skillsData: [],
      skillsId: 0,
      abilityId: '',
      isPackUp: false,
      iconFlag: false,
      abilityName: '',
      abilityState: false,
      knowledgePointUpdateKeyword: '',
      knowledgePointsUpdate: [],
      ds: {
        series: [
          {
            type: 'tree',
            name: '知识点',
            data: [],
            top: '5%',
            left: '10%',
            bottom: '5%',
            right: '10%',
            symbolSize: 25,
            symbol: `image://${icon}`,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            roam: true,
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      },
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      knowledgeList: [],
      addKnowledgeList: [],
      treeChart: null,
      describe: '',
      flag: true,
      num: 1
    }
  },
  mounted() {
    this.getOrgAbility()
    this.getKnowledgeTree()
  },
  methods: {
    handleNodeClick(data) {
      this.skillsId = data.id
      this.abilityName = data.name
      this.iconFlag = true
      this.abilityState = false
      this.knowledgeKeyword = ''
      this.$refs['knowledge-tree-update'].setCheckedKeys([])
      this.getOrgAbilityInfo()
    },
    resetTree() {
      this.$set(this.ds.series[0], 'top', '5%')
      this.$set(this.ds.series[0], 'left', '8%')
      this.$set(this.ds.series[0], 'right', '10%')
      this.$refs['chart'].mergeOptions(this.ds, true)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    toFilterNodes(ref) {
      this.$refs[ref].filter(this.keyword)
      this.$refs[ref].setCheckedNodes([])
    },
    setTreeState() {
      if (this.num === 1) {
        this.$nextTick(() => {
          this.flag = true
        })
      } else {
        this.$nextTick(() => {
          this.flag = false
        })
      }
      if (this.num > 2) {
        this.num = 1
      } else {
        this.num = this.num + 1
      }
    },
    getKnowledgeTree() {
      //获取知识点树
      questionApi.getKnownLedgeTree().then(res => {
        this.knowledgePointsUpdate = res.res
      })
    },
    toFilterNodeUpdate(ref, keyword) {
      //知识点树过滤点击触发(题目卡片)
      this.$refs[ref].filter(keyword)
      if (this.knowledgeList.length > 0) {
        let id = this.knowledgeList.map(item => {
          return item.knowledgeId
        })
        this.$refs[ref].setCheckedKeys(id)
      }
    },
    //获取技能知识点
    getAbilityKnowledge() {
      knowledgeApi.getAbilityKnowledge(this.skillsId).then(res => {
        this.$refs['chart'].clear()
        this.ds.series[0].data = [
          {
            id: res.res.abilityId,
            name: res.res.abilityName,
            children: res.res.tree
          }
        ]
        this.abilityState = true
        this.resize()
      })
    },
    openAbilityInfo() {
      this.getAbilityKnowledge()
    },
    closeAbilityInfo() {
      this.abilityState = false
    },
    //修改技能描述
    editDescribeInfo() {
      let payload = {
        abilityId: this.skillsId,
        value: this.describe
      }
      abilityApi.editDescribe(payload).then(res => {
        if (res.res) {
          this.$message.success('技能描述修改成功')
        }
      })
    },
    //获取技能
    getOrgAbility() {
      abilityApi.getOrgAbility('').then(res => {
        this.skillsData = res.res
        this.abilityName = this.skillsData[0].name
        this.skillsId = this.skillsData[0].id
        this.getOrgAbilityInfo()
        this.$nextTick(() => {
          this.$refs['functionAbility-tree'].setCurrentKey(this.skillsId)
        })
      })
    },
    //删除技能
    delAbility() {
      abilityApi.delOrgAbility(this.abilityId).then(res => {
        if (res.code === 0) {
          this.$message.success('技能删除成功')
          this.getOrgAbility()
        }
      })
    },
    //删除知识点
    delOrgAbilityknowledge(data) {
      let payload = {
        abilityId: this.skillsId,
        knowledgeIds: [data.knowledgeId]
      }
      abilityApi.delOrgAbilityknowledge(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('知识点删除成功')
          this.getOrgAbilityInfo()
        }
      })
    },
    //重置选中知识点
    resetCheckedNodesDown() {
      this.$refs['knowledge-tree-update'].setCheckedKeys([])
    },
    //获取选中知识点id
    toSearchByKnowledge() {
      this.addKnowledgeList = this.$refs['knowledge-tree-update'].getCheckedKeys()
      this.addOrgAbilityknowledge()
    },
    //添加知识点
    addOrgAbilityknowledge() {
      let payload = {
        abilityId: this.skillsId,
        knowledgeIds: this.addKnowledgeList
      }
      abilityApi.addKnowledge(payload).then(res => {
        if (res.code === 0) this.$message.success('知识点添加成功')
        this.getOrgAbilityInfo()
      })
    },
    setCurrentKey(id) {
      this.$nextTick(() => {
        this.$refs['functionAbility-tree'].setCurrentKey(id ? id : this.skillsData[0].id)
      })
    },
    //模糊查询知识点
    searchKnowledge() {
      this.iconFlag = false
      if (this.knowledgeKeyword === '') {
        this.getOrgAbilityInfo()
      } else {
        let searchKnowledgeList = this.knowledgeList.filter(x => {
          if (x.knowledgeName.indexOf(this.knowledgeKeyword) !== -1) {
            return x
          }
        })
        this.knowledgeList = searchKnowledgeList
      }
    },
    //获取技能详情
    getOrgAbilityInfo() {
      abilityApi.getOrgAbilityInfo(this.skillsId).then(res => {
        this.describe = res.res.describe
        if (res.res.knowledgePointVOS === null) {
          this.knowledgeList = []
        } else {
          this.knowledgeList = res.res.knowledgePointVOS
        }
        this.iconFlag = true
        this.$nextTick(() => {
          this.toFilterNodeUpdate('knowledge-tree-update', '')
        })
      })
    },
    //获取知识点树
    getTree(id) {
      this.$refs['chart'].clear()
      knowledgeApi.getKnowledgeChildren(id).then(res => {
        this.ds.series[0].data = [
          {
            id: res.res.id,
            name: res.res.name,
            description: res.res.description,
            children: res.res.children
          }
        ]
        this.abilityState = true
        this.resize()
      })
    },
    resize() {
      this.$refs['chart'].resize()
    },
    toCreateFunctionAbility(data) {
      this.$refs.functionAbilityCUDialog.open(data)
    },
    toUpdateFunctionAbility(data) {
      this.$refs.functionAbilityCUDialog.update(false, data)
    },
    toDeleteFunctionAbility(data) {
      this.$refs.deleteDialog.open()
      this.abilityId = data.id
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/filterDropdownMenu';
.yt-container {
  height: 100% !important;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.yt-search {
  padding: 0px 8px;
  margin-top: 8px;
  height: 32px;
}
.list-container {
  height: calc(100% - 106px);
  width: 200px;
  overflow-y: auto;
  .el-dropdown {
    .flexStyle(flex, flex-start, center);
    height: 20px;
    line-height: 20px;
    color: #000000;
  }
  .el-icon-arrow-down {
    font-size: @small;
    color: #999999 !important;
    transition: all 0.2s ease-in-out;
  }
}
.yt-content-tree {
  //margin-right: 0;
}
.yt-container {
  height: calc(100% - 30px);
}
.yt-content {
  padding: 14px 16px 20px 16px !important;
  overflow-y: auto;
  width: (100% - 170px);
  position: relative;
  .knowledge-content {
    padding: 15px 16px 20px 16px;
    margin-top: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 999;
    /*right: 30px;*/
    top: 210px;
    border-radius: 4px;
    width: calc(100% - 30px);
    height: 100px;
    background: #ffffff;
    position: absolute;
    animation: myfirst 2s;
    -webkit-animation: myfirst 1s;
    animation-fill-mode: forwards;
    p {
      font-size: 13px;
      font-weight: bold;
    }
    @-webkit-keyframes myfirst /* Safari and Chrome */ {
      0% {
        background: #ffffff;
        bottom: 50px;
      }
      100% {
        background: #ffffff;
        height: calc(100% - 260px);
        bottom: 50px;
      }
    }
  }
  .top-content {
    display: flex;
    justify-content: space-between;
    .top-lable {
      display: flex;
      p {
        font-size: 14px;
        font-weight: bold;
        padding-top: 3px;
      }

      .ability-name {
        min-width: 97px;
        height: 26px;
        margin-top: 3px;
        padding-left: 20px;
        padding-right: 20px;
        background: #448bff;
        border-radius: 4px;
        opacity: 1;
        margin-left: 5px;
        text-align: center;
        span {
          font-size: 12px;
          color: #ffffff;
          display: block;
          margin-top: 4px;
        }
      }
      svg {
        font-size: 16px;
        fill: #448bff;
        margin-top: 8px;
        margin-left: 1px;
      }
      :hover {
        fill: #448bff;
      }
    }
  }
  .line {
    margin-top: 16px;
    height: 1px;
    background: #e2e4e8;
    opacity: 1;
  }
  .middle-content {
    display: flex;
    p {
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
    }
    svg {
      font-size: 18px;
      margin-left: 8px;
      fill: #448bff;
      margin-top: 24px;
    }
    :hover {
      fill: #448bff;
    }
  }
  .bottom-content {
    display: flex;
    p {
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
      width: 80px;
    }
    .icon-svg {
      font-size: 17px;
      margin-top: 22px;
      margin-left: -8px;
      fill: #448bff;
      :hover {
        fill: #448bff;
      }
    }
    .add-icon {
      font-size: 15px;
      margin-top: 8px;
      margin-left: 10px;
      fill: #999999;
    }
    .tag {
      display: flex;
      margin-right: 10px;
      margin-bottom: 15px;
      .second-icon {
        font-size: 30px;
        margin-top: -2px;
        fill: #dc6fff;
      }
      &:hover {
        svg {
          fill: #cc2cff;
        }
        span {
          background: #cc2cff;
        }
      }
    }
    .tag-item {
      display: flex;
      margin-right: 10px;
      margin-bottom: 15px;
      .first-icon {
        font-size: 30px;
        margin-top: -2px;
        fill: #2a9bff;
      }
      &:hover {
        svg {
          fill: #007fef;
        }
        span {
          background: #007fef;
        }
      }
    }
  }
}
.not-have {
  ::v-deep .el-tag {
    height: 26px;
    border-radius: 0;
    border-width: 0;
    border-style: none;
    background-color: #2a9bff;
    padding: 0 10px 0 0;
    border-color: #2a9bff;
    color: #ffffff;
    line-height: 27px;
    margin-left: -2px;

    .el-tag__close {
      color: #ffffff;
    }

    &:hover {
      background-color: #007fef;
    }
  }
}
.have {
  ::v-deep .el-tag {
    height: 26px;
    border-radius: 0;
    background-color: #dc6fff;
    border-color: #dc6fff;
    color: #ffffff;
    padding: 0 10px 0 0;
    border-width: 0;
    border-style: none;
    line-height: 27px;
    margin-left: -2px;

    .el-tag__close {
      color: #ffffff;
      background-color: #dc6fff;
    }

    &:hover {
      background-color: #cc2cff;
    }
  }
}
::v-deep .el-button {
  padding: 6px 15px;
}
::v-deep .el-input__icon {
  width: 40px;
}
.yt-container-flex .yt-content-tree .tree {
  margin-top: 0 !important;
}
</style>
<style lang="less">
.tooltip {
  background: #ffffff !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  padding: 10px !important;
  color: #000000 !important;
  text-align: center !important;
}
.tooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow {
  border-top-color: #ffffff !important;
}
.tooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow:after {
  border-top-color: #ffffff !important;
}
</style>
